import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { layouts } from '@/utils/preset'
import { tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Headline from '@/components/app/Headline'
import Typography from '@/components/common/Typography'

const AboutIntegration = props => {
  return (
    <Root {...props}>
      <Head>
        <Title theme={props.theme}>
          各周辺システムとの<br />
          <strong>柔軟なデータ連携が可能</strong>
        </Title>
      </Head>
      <Main>
        <Body variant="body1">
          外部システムなどとのデータ連携機能を保持しています。ファイル連携による方法が一般的となります。インポート・エクスポートの両機能がありますので、データの受け入れ・出力の両方ができます。例えば、作成された会計仕訳データを別の税務申告用ソフトに取り込んで確定申告を行うことが可能です。freee、MFクラウド、弥生会計、勘定奉行など各種システムと連携可能です。
        </Body>
      </Main>
    </Root>
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileS)} {
    br {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const Body = styled(Typography)`
  margin: 0 auto;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  text-align: center;
  @media ${mq.and(tabletS)} {
    text-align: left;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
  }
  @media ${mq.and(mobile)} {
    text-align: left;
    br {
      display: none;
    }
  }
`

export default ThemeConsumer(AboutIntegration)
