import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
// import { layouts } from '@/utils/preset'
import { desktop, laptop, tablet, tabletL, tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { Link } from 'gatsby'
import Headline from '@/components/app/Headline'
import LinkButton1 from '@/components/common/LinkButton1'
import Card from './LeadCard'

const PricingLead = props => {
  return (
    <Root {...props}>
      <Head>
        <Title theme={props.theme}>
          月額わずか<strong>50,000円〜</strong><br />
          導入・サポートプランも<br />
          <strong>充実で安心</strong>
        </Title>
      </Head>
      <Main>
        <CardList>
          <CardList_Container>
            <CardList_Item>
              <Card
                title="導入費用"
                price={(
                  <Fragment>
                    30,000<small>円 〜</small>
                  </Fragment>
                )}
                content={(
                  <Fragment>
                    <h4>オプションサービス</h4>
                    <p>
                      導入メニューの中から必要に応じてサービスをお選びいただけます。<br />
                      ・導入支援・コンサルティング<br />
                      ・拡張・連携機能開発<br />
                      ・既存データ移行<br />
                      ・帳票カスタマイズ<br />
                      ・補助金（IT導入補助金等）申請サポート<br />
                      ・社内向け説明会
                    </p>
                  </Fragment>
                )}
              />
            </CardList_Item>
            <CardList_Item>
              <Card
                color="primary"
                title="基本料金"
                price={(
                  <Fragment>
                    50,000<small>円 〜 / 月</small>
                  </Fragment>
                )}
                content={(
                  <Fragment>
                    <h4>すべての機能がご利用いただけけます</h4>
                    <p>
                      財務会計／販売管理／購買管理／経費申請管理／在庫管理／データ分析／データダウンロード／メール通知／カスタマイズ／データ連携
                    </p>
                    <p>
                      <small><strong>※ 条件付きで月額30,000円のプランもご用意しています。詳しくはお問い合わせください。</strong></small>
                    </p>
                  </Fragment>
                )}
              />
            </CardList_Item>
            <CardList_Item>
              <Card
                title="導入後サポート"
                price={(
                  <Fragment>
                    10,000<small>円 〜</small>
                  </Fragment>
                )}
                content={(
                  <Fragment>
                    <h4>テクニカルサポート</h4>
                    <p>
                      スタンダード／ミドル／プレミアムから選択
                    </p>
                    <h4>オプション</h4>
                    <p>
                      ・認定アドバイザーによる各種コンサルティング（経営・税務・法務・労務等）<br />
                      ・データ分析、ビジネス適用支援
                    </p>
                  </Fragment>
                )}
              />
            </CardList_Item>
          </CardList_Container>
        </CardList>
      </Main>
      <Foot>
        <MoreButton to="/pricing/" component={Link} color="primary">料金・サポートプラン</MoreButton>
      </Foot>
    </Root>
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(desktop, laptop, tablet)} {
    br:nth-of-type(2) {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 90px;
  @media ${mq.and(mobile)} {
    margin-top: 48px;
  }
`

const CardList = styled.div`
  @media ${mq.and(mobile)} {
    margin-top: 16px;
  }
`

const CardList_Container = styled.ul`
  display: block;
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const CardList_Item = styled.li`
  padding: 0 8px;
  @media ${mq.and(desktop, laptop, tabletL)} {
    width: ${100 / 3}%;

    > * {
      height: 100%;
    }
  }
  @media ${mq.and(tabletS, mobile)} {
    width: 100%;
    margin-top: 16px;
  }
`

const Foot = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
`

const MoreButton = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

export default ThemeConsumer(PricingLead)
