import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'

const Bg = props => {
  const { ...others } = props
  return (
    <Root {...others}>
    </Root>
  )
}

export default ThemeConsumer(Bg)

const Root = styled.div`
  background-image: linear-gradient(-45deg, #fff 25%, ${props => props.theme.gray.b} 25%, ${props => props.theme.gray.b} 50%, #fff 50%, #fff 75%, ${props => props.theme.gray.b} 75%, ${props => props.theme.gray.b});
  background-size: 27.777px 27.777px;
`
