import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor } from '@/utils/themes'
import { tabletL, tabletS, mobile, mobileL, mobileM, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { layouts } from '@/utils/preset'
import Headline from '@/components/app/Headline'
import Typography from '@/components/common/Typography'

const AboutCompare = props => {
  const { theme } = props
  return (
    <StaticQuery
      query={query}
      render={data => {
        const { head, rows } = data.table.edges[0].node
        return (
          <Root {...props}>
            <Head>
              <Title theme={theme}>
                他社では実現できない要望も<br />
                FVPなら<strong>すべて対応可能</strong>
              </Title>
            </Head>
            <Main>
              <Container>
                <Table theme={theme}>
                  <TableHead>
                    <TableRow>
                      {head.map((title, index) => (
                        <TableHeader
                          key={index}
                          variant="body1"
                          component="th"
                          color={index === 1 ? 'primary' : null}
                          theme={theme}
                          dangerouslySetInnerHTML={{ __html: title }}
                        />
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody theme={theme}>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableHeader
                          key={index}
                          variant="body2"
                          component="th"
                          theme={theme}
                          dangerouslySetInnerHTML={{ __html: row.head }}
                        />
                        {row.cells.map((cell, index) => (
                          <TableCell
                            key={index}
                            variant="body2"
                            component="td"
                            theme={theme}
                          >
                            <Score color={index === 0 ? 'primary' : null} theme={theme}>{cell.score}</Score>
                            <Desc
                              dangerouslySetInnerHTML={{ __html: cell.desc }}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Container>
            </Main>
          </Root>
        )
      }}
    />
  )
}

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileS)} {
    br {
      display: none;
    }
  }
`

const Main = styled(layouts.components.FullWidth)`
  margin-top: 90px;
  @media ${mq.and(mobile)} {
    margin-top: 48px;
  }
`

const Container = styled.div`
  margin: 0 auto;
  box-sizing: content-box;
  padding: 0 ${layouts.defs.pad.base}px;
  max-width: ${layouts.defs.maxWidth}px;

  @media ${mq.and(tabletL)} {
    padding: 0 ${layouts.defs.pad.tablet}px;
  }

  @media ${mq.and(tabletS, mobile)} {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 0 0 8px;
  }
`

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  border: 1px solid ${props => props.theme.line.base};
`

const Score = styled.div`
  ${props => props.color && `color: ${getThemeColor(props.color, props.theme)}`};
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  @media ${mq.and(mobile)} {
    font-size: 24px;
    line-height: 1.2;
  }
`

const Desc = styled.div`
  text-align: center;
`

const TableCell = styled(Typography)`
  padding: 32px;
  width: 1%;
  border: 1px solid ${props => props.theme.line.base};
  background-color: ${props => props.theme.gray.a};
  vertical-align: middle;
  @media ${mq.and(tabletS, mobileL)} {
    width: 180px;
    padding: 24px;
  }
  @media ${mq.and(mobileM, mobileS)} {
    width: 150px;
    padding: 8px;
    font-size: 12px;
    br {
      display: none;
    }
  }
  &:last-of-type {
    border-right: none;
  }
`

const TableHeader = styled(TableCell)`
  ${props => props.color && `color: ${getThemeColor(props.color, props.theme)}`};
  font-weight: bold;
  padding: 24px;
  span {
    display: block;
    white-space: nowrap;
  }
  @media ${mq.and(tabletS, mobileL)} {
    padding: 16px;
  }
  @media ${mq.and(mobileM, mobileS)} {
    padding: 8px;
  }
  &:nth-of-type(1) {
    position: sticky;
    left: 0;
    z-index: 1;
    font-weight: normal;
    border-left: none;
    @media ${mq.and(tabletS, mobile)} {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -1px;
        bottom: 0;
        background-color: ${props => props.theme.gray.b};
        width: 1px;
      }
    }
  }
`

const TableRow = styled.tr`
  &:last-of-type > * {
    border-bottom: none;
  }
`

const TableBody = styled.tbody`
  & ${TableHeader} {
    vertical-align: middle;
    background-color: ${props => props.theme.gray.b};
    @media ${mq.and(tabletS, mobile)} {
      width: 140px;
    }
  }
`

const TableHead = styled.thead`
  & ${TableHeader} {
    text-align: center;
    &:nth-of-type(1) {
      @media ${mq.and(tabletS, mobile)} {
        width: 140px;
      }
    }
  }
`

export default ThemeConsumer(AboutCompare)

export const query = graphql`
  query AboutCompareQuery {
    table: allAboutCompareYaml {
      edges {
        node {
          head
          rows {
            head
            cells {
              score
              desc
            }
          }
        }
      }
    }
  }
`
