import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tablet, tabletL, tabletS, mobile, mobileM, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import { getThemeColor } from '@/utils/themes'

const Template = props => {
  const { color, title, price, content, ...others } = props
  return (
    <Root {...others}>
      <Head color={color} theme={props.theme}>
        <Title color={color} variant="h5" component="h3" theme={props.theme}>{title}</Title>
        <Price color={color} theme={props.theme}>{price}</Price>
      </Head>
      <Main>
        <Content theme={props.theme}>{content}</Content>
      </Main>
    </Root>
  )
}

export default ThemeConsumer(Template)

const Root = styled.div`
  border: 1px solid ${props => props.theme.line.base};
  display: flex;
  flex-direction: column;
  @media ${mq.and(tabletS)} {
    flex-direction: row;
  }
`

const Head = styled.div`
  padding: 26px 24px 35px;
  @media ${mq.and(tabletL)} {
    padding: 26px 16px 35px;
  }
  @media ${mq.and(tabletS)} {
    width: 50%;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media ${mq.and(mobile)} {
    padding: 18px 16px 24px;
  }
  background-color: ${props => props.color
    ? getThemeColor(props.color, props.theme)
    : props.theme.gray.b
  };
  flex-grow: 0;
  flex-shrink: 0;
`

const Title = styled(Typography)`
  color: ${props => props.color
    ? getThemeColor(props.color, props.theme, 'contrast')
    : props.theme.gray.e
  };
  text-align: center;
  @media ${mq.and(mobileM, mobileS)} {
    font-size: 18px;
  }
`

const Price = styled.h4`
  margin-top: 10px;
  text-align: center;
  font-size: 44px;
  @media ${mq.and(tablet, mobile)} {
    font-size: 40px;
  }
  font-weight: bold;
  color: ${props => props.color
    ? getThemeColor(props.color, props.theme, 'contrast')
    : props.theme.primary.base
  };
  small {
    font-size: 0.4em;
  }
`

const Main = styled.div`
  padding: 24px;
  @media ${mq.and(tabletL)} {
    padding: 24px 16px;
  }
  @media ${mq.and(mobile)} {
    padding: 18px 16px;
  }
  flex-grow: 1;
  flex-shrink: 1;
`

const Content = styled.div`
  margin-top: -10px;

  * {
    margin-top: 10px;
  }

  h1, h2, h3, h4, h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    color: ${props => props.theme.gray.e};
  }

  p {
    font-size: 14px;
    line-height: 1.75;
  }

  small {
    font-size: 0.9em;
  }

  strong {
    font-weight: bold;
    color: ${props => props.theme.primary.base};
  }
`
